<template>
  <mly-pc-wrap path="./gift"></mly-pc-wrap>
</template>

<script>
export default {

}
</script>

<style>

</style>
